import { ChangeDetectorRef, Component, ElementRef, inject, Type, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { AppConfig, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { Booking, BookingStatus, CheckoutParams } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedErrorService } from '@luggagehero/shared/services/error';
import { SharedNotificationService } from '@luggagehero/shared/services/notification';
import { SharedPricingService } from '@luggagehero/shared/services/pricing';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';
import { SharedWindowService } from '@luggagehero/shared/services/window';
import { SharedUiConfirmButtonComponent } from '@luggagehero/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { DateUtil } from '../../../../../utils/date.util';
import { NumberUtil } from '../../../../../utils/number.util';
import { StringUtil } from '../../../../../utils/string.util';
import { BookingActionBaseComponent } from './booking-action.base-component';
import { BookingActionInfo } from './booking-action-info';
import { ShowReceiptActionBaseComponent } from './show-receipt-action.base-component';
import { ValidateBookingActionBaseComponent } from './validate-booking-action.base-component';

interface TipOptions {
  amount: number;
  label: string;
}

// Used for calculating tip options when we cannot get info via order or pricing
const DEFAULT_BOOKING_TOTAL = 20; // 20 eur/gbp/usd
const DEFAULT_HOURLY_RATE = 2; // 2 eur/gbp/usd

const DEFAULT_BOOKING_TOTAL_DKK = DEFAULT_BOOKING_TOTAL * AppConfig.DKK_TIP_CONVERSION_RATE;
const DEFAULT_HOURLY_RATE_DKK = DEFAULT_HOURLY_RATE * AppConfig.DKK_TIP_CONVERSION_RATE;

const DEFAULT_BOOKING_TOTAL_SEK = DEFAULT_BOOKING_TOTAL * AppConfig.SEK_TIP_CONVERSION_RATE;
const DEFAULT_HOURLY_RATE_SEK = DEFAULT_HOURLY_RATE * AppConfig.SEK_TIP_CONVERSION_RATE;

const DEFAULT_BOOKING_TOTAL_NOK = DEFAULT_BOOKING_TOTAL * AppConfig.NOK_TIP_CONVERSION_RATE;
const DEFAULT_HOURLY_RATE_NOK = DEFAULT_HOURLY_RATE * AppConfig.NOK_TIP_CONVERSION_RATE;

const TIP_FACTOR_LG = 5;
const TIP_FACTOR_MD = 3;
const TIP_FACTOR_SM = 1;

@Component({ template: '' })
export abstract class PickUpActionBaseComponent extends BookingActionBaseComponent {
  @ViewChild('customTipInput') public customTipInput: ElementRef<HTMLInputElement>;
  @ViewChild('customTip') public customTipModel: NgModel;
  @ViewChild('confirmButton') public confirmButton: SharedUiConfirmButtonComponent;
  public isCustomTipVisible = false;
  public isSlideToConfirmEnabled = AppConfig.IS_SLIDE_TO_CONFIRM_ENABLED;
  public showExpandedImage = false;

  private sharedAppSettingsService = inject<SharedAppSettingsService>(SharedAppSettingsService);

  private currency = 'EUR';
  private _tip: number;
  private _readyToCollect = false;
  private _isPaymentMethodChanged = false;
  private _isConfirmPickupClicked = false;
  public tipOptions: TipOptions[] = [];
  public customTipValue: number;

  public accessCode: string;
  private _isSelfService = false;

  constructor(
    private translate: TranslateService,
    private error: SharedErrorService,
    private notify: SharedNotificationService,
    bookingService: SharedBookingService,
    shopsService: SharedShopsService,
    storageService: SharedStorageService,
    cd: ChangeDetectorRef,
    private priceService: SharedPricingService,
    protected windowService: SharedWindowService,
  ) {
    super(bookingService, shopsService, storageService, cd);
  }

  public get isSelfService(): boolean {
    return this._isSelfService;
  }

  public async getStorageAccessCode(): Promise<string | null> {
    if (!this.booking || !this.accessAllowed) {
      return null;
    }

    let accessCode = '';

    try {
      accessCode = await this.bookingService.getStorageAccessCode(this.booking._id);
    } catch (err) {
      accessCode = this.shop.storageRoomAccessBackupCode || null;
    }

    return accessCode;
  }

  public get accessAllowed(): boolean {
    const alwaysAllowStatus: BookingStatus[] = ['CONFIRMED', 'CHECKED_IN'];
    const expiringStatus: BookingStatus[] = ['CHECKED_OUT', 'PAID'];
    if (alwaysAllowStatus.includes(this.booking.status)) {
      return true;
    }

    if (
      expiringStatus.includes(this.booking.status) &&
      moment().isBefore(
        moment(this.booking.period.checkOut).add(
          this.sharedAppSettingsService.current.maxTimeMinutesPinReuse,
          'minutes',
        ),
      )
    ) {
      return true;
    }

    return false;
  }

  get showPickupConfirmationActionInfo(): BookingActionInfo {
    return new BookingActionInfo('ShowPickUpConfirmationFromDropOffConfirmation')
      .withCallToAction(this._translate('SHOW_PICKUP_CONFIRMATION_LONG'))
      .withAction({ componentToShow: this.validateBookingActionComponentType })
      .withTitle(null)
      .withState(this.booking.paidDirectly ? 'warning' : 'success');
  }

  get retryPaymentActionInfo(): BookingActionInfo {
    let actionInfo = new BookingActionInfo('RetryPaymentFromDropOffConfirmation')
      .withCallToAction(this._translate('RETRY_PAYMENT_BUTTON'))
      .withAction({ componentToShow: this.showReceiptActionComponentType })
      .withTitle(this._translate('PAYMENT_FAILED'))
      .withState('error')
      .withBookingChangeHandler((b) => this.onRetryPayment(b));

    if (this.booking.paymentPostponedAt) {
      actionInfo = actionInfo.withSkipAction({
        functionToCall: () => {
          this.actionRequest.emit(this.showPickupConfirmationActionInfo);
          return Promise.resolve(null);
        },
      });
    }

    return actionInfo;
  }

  get currencySymbol(): string {
    return StringUtil.getCurrencySymbol(this.currency);
  }

  get luggageImage(): string {
    if (!this.booking?.luggage?.smartImages?.length) {
      return null;
    }
    if (!AppConfig.SHOPS_WITH_LUGGAGE_IMAGE_BEFORE_PICKUP.includes(this.booking.shopId)) {
      return null;
    }
    return this.booking.luggage.smartImages[0].name;
  }

  get isStorageTimerEnabled(): boolean {
    return AppConfig.IS_STORAGE_TIMER_IN_PICKUP_ACTION_ENABLED;
  }

  get tip(): number {
    return this._tip;
  }
  set tip(value: number) {
    if (typeof value === 'string') {
      // The binding to the input element gives us a string value, so we need to convert it to a number
      value = value && !isNaN(value) ? Number(value) : undefined;
    }
    this._tip = value;
    this.cd.markForCheck();
  }

  get readyToCollect(): boolean {
    return this._readyToCollect;
  }
  set readyToCollect(value: boolean) {
    this._readyToCollect = value;
    this.cd.markForCheck();
  }

  get isPaymentMethodChanged(): boolean {
    return this._isPaymentMethodChanged;
  }
  set isPaymentMethodChanged(value: boolean) {
    this._isPaymentMethodChanged = value;
    this.cd.markForCheck();
  }

  get isConfirmPickupClicked(): boolean {
    return this._isConfirmPickupClicked;
  }
  set isConfirmPickupClicked(value: boolean) {
    this._isConfirmPickupClicked = value;
    this.cd.markForCheck();
  }

  get isMissingTipSelection(): boolean {
    if (!this.isConfirmPickupClicked) {
      return false;
    }
    return !this.canStopTimer;
  }

  get isCustomTipInvalid(): boolean {
    if (!this.isConfirmPickupClicked) {
      return false;
    }
    if (!this.isCustomTipVisible) {
      return false;
    }
    return this.customTipModel.invalid || this.tip === undefined;
  }

  get isTipTooLarge(): boolean {
    if (!this.isConfirmPickupClicked) {
      return false;
    }
    if (!AppConfig.IS_BIG_TIP_DISABLED) {
      return false;
    }
    return this.tip > this.maxTip;
  }

  get showTip(): boolean {
    if (this.maxTip < 1) {
      return false;
    }
    if (this.booking.isZeroPaymentBooking) {
      return false;
    }

    return this.shop.isPickUpTipEnabled && AppConfig.IS_TIPPING_ENABLED;
  }

  get estimatedTotal(): number {
    // Make sure we have an estimated total (use defaults if not available)

    const currencyEstimatedTotals: { [key: string]: number } = {
      DKK: DEFAULT_BOOKING_TOTAL_DKK,
      SEK: DEFAULT_BOOKING_TOTAL_SEK,
      NOK: DEFAULT_BOOKING_TOTAL_NOK,
    };

    return NumberUtil.valid(
      this.booking?.price?.estimated?.total,
      currencyEstimatedTotals[this.latestOrder.currency.toUpperCase()] || DEFAULT_BOOKING_TOTAL,
    );
  }

  get hourlyRate(): number {
    const currencyHourlyRates: { [key: string]: number } = {
      DKK: DEFAULT_HOURLY_RATE_DKK,
      SEK: DEFAULT_HOURLY_RATE_SEK,
      NOK: DEFAULT_HOURLY_RATE_NOK,
    };

    // Make sure we have an hourly rate (use defaults if not available)
    return NumberUtil.valid(
      this.getHourlyRateUnitPrice(),
      currencyHourlyRates[this.latestOrder.currency.toUpperCase()] || DEFAULT_HOURLY_RATE,
    );
  }

  get tipLimit(): number {
    const currencyTipLimits: { [key: string]: number } = {
      DKK: AppConfig.TIP_LIMIT_DKK,
      SEK: AppConfig.TIP_LIMIT_SEK,
      NOK: AppConfig.TIP_LIMIT_NOK,
    };

    let limit = currencyTipLimits[this.latestOrder.currency.toUpperCase()] || AppConfig.TIP_LIMIT;
    if (DateUtil.minutesSinceCheckIn(this.booking) < 60) {
      limit /= 10; // Lower limit if booking was checked in less than an hour ago
    }
    return limit;
  }

  get maxTip(): number {
    if (this.estimatedTotal === 0) {
      // Don't allow tips for free bookings
      return 0;
    }

    if (this.booking.orderDetails && this.booking.orderDetails[0].tip?.maxTip > 0) {
      return this.booking.orderDetails[0].tip.maxTip;
    }

    // Get the biggest of the two ways to calculate the max tip
    const maxTip = Math.max(
      this.estimatedTotal * AppConfig.MAX_TIP_FACTOR_ESTIMATED_TOTAL,
      this.hourlyRate * AppConfig.MAX_TIP_FACTOR_HOURLY_RATE,
    );

    // Apply tip limit if it's lower than the calculated max tip and return the rounded value
    return Math.round(Math.min(maxTip, this.tipLimit));
  }

  get maxTipFormatted(): string {
    return this.priceService.format(this.maxTip, this.currency);
  }

  get canStopTimer(): boolean {
    if (this.isLoading) {
      return false;
    }
    if (!this.isConfirmPickupClicked) {
      return true;
    }
    if (this.booking.isZeroPaymentBooking) {
      return true;
    }

    if (!this.showTip) {
      return true;
    }
    if (this.tip === undefined) {
      return false;
    }
    if (this.tip > this.maxTip && AppConfig.IS_BIG_TIP_DISABLED) {
      return false;
    }
    return true;
  }

  protected abstract get showReceiptActionComponentType(): Type<ShowReceiptActionBaseComponent>;
  protected abstract get validateBookingActionComponentType(): Type<ValidateBookingActionBaseComponent>;

  async onBaseInit() {
    if (!this.shop || !this.shopsService.latestPrice) {
      this.shop = await this.shopsService.getShopDetails(
        this.booking.shopId,
        DateUtil.serializeDate(this.booking.period.from),
        DateUtil.serializeDate(this.booking.period.to),
        this.booking.luggage.normal,
        this.booking.luggage.hand,
        this.booking.isGuest || this.booking.isWalkIn ? 'dropoff' : 'marketplace',
      );
    }

    // find the order line with one of the hourly rate keys
    const hourlyRate = this.getHourlyRateUnitPrice();

    this.currency = this.latestOrder.currency.toUpperCase();

    console.log(`Initialising tip options with hourly rate: ${hourlyRate}, currency: ${this.currency}`);
    this.initialiseTipOptions(hourlyRate);
    if (this.booking.orderDetails && this.booking.orderDetails[0].orderLines) {
      this.booking.orderDetails[0].orderLines.forEach((orderLine) => {
        if (orderLine.product.name === 'PRICE_TIP') {
          this.tip = orderLine.unitPrice;
          if (!this.tipOptions.some((option) => option.amount === this.tip)) {
            this.customTipValue = this.tip;
            this.isCustomTipVisible = true;
          }
        }
      });
    }

    this._isSelfService =
      this.shop.storageRoomAccessCode || this.shop.storageRoomAccessText || this.shop.hasALock ? true : false;

    if (this._isSelfService) {
      if (this.shop.hasALock) {
        this.accessCode = await this.getStorageAccessCode();
      } else {
        this.accessCode = this.shop.storageRoomAccessCode || '';
      }
    }
  }

  private getHourlyRateUnitPrice(): number {
    const hourlyRateKeys = ['hourlyRate', 'WALK_IN_HOURLY_RATE', 'GUEST_HOURLY_RATE'];

    const hourlyRateProduct = this.latestOrder.orderLines.find((orderLine) =>
      hourlyRateKeys.includes(orderLine.product._id),
    );

    return hourlyRateProduct ? hourlyRateProduct.unitPrice : 0;
  }

  private initialiseTipOptions(hourlyRate: number) {
    if (this.booking.orderDetails && this.booking.orderDetails[0].tip) {
      this.booking.orderDetails[0].tip.options.forEach((amount) => {
        this.tipOptions.push({ amount, label: this.priceService.format(amount, this.currency) });
      });
    } else {
      // If the large tip option is too high when using the hourly rate, ceil the tip options using the max tip
      const useMaxTip = hourlyRate * TIP_FACTOR_LG > this.maxTip;

      const opt0 = 0; // Always include a 0 tip option
      const opt1 = Math.round(useMaxTip ? this.maxTip / 4 : hourlyRate * TIP_FACTOR_SM);
      const opt2 = Math.round(useMaxTip ? this.maxTip / 2 : hourlyRate * TIP_FACTOR_MD);
      const opt3 = Math.round(useMaxTip ? this.maxTip : hourlyRate * TIP_FACTOR_LG);

      this.tipOptions.push({ amount: opt0, label: this.priceService.format(opt0, this.currency) });
      this.tipOptions.push({ amount: opt1, label: this.priceService.format(opt1, this.currency) });
      this.tipOptions.push({ amount: opt2, label: this.priceService.format(opt2, this.currency) });
      this.tipOptions.push({ amount: opt3, label: this.priceService.format(opt3, this.currency) });
    }
  }

  public abstract showLuggageImage(e: Event): void;

  public async checkOut() {
    this.isConfirmPickupClicked = true;
    if (!this.canStopTimer) {
      this.confirmButton.reset();
      return;
    }

    const params: CheckoutParams = { tip: this.tip || 0 };

    const isTipValid = this.validateTip(params.tip);
    if (!isTipValid) {
      this.confirmButton.reset();
      return;
    }

    this.isLoading = true;

    try {
      const res = await this.bookingService.checkOut(this.booking._id, params);

      this.updateBooking(res);
      this.isLoading = false;

      this.close();
      this.onCheckOut(this.booking);

      if (this.booking.status !== 'PAID') {
        // this.notify.error(this.translate.instant('PAYMENT_FAILED'));
      }

      this.isPaymentMethodChanged = false;
    } catch (err) {
      this.isLoading = false;
      this.error.handleError(err, this.translate.instant('UNEXPECTED_ERROR') as string);
    }
  }

  public setTipAmount(value: number) {
    this.tip = value;
    this.isCustomTipVisible = false;
  }

  public toggleCustomTip() {
    this.isCustomTipVisible = !this.isCustomTipVisible;
    this.cd.markForCheck();

    if (this.isCustomTipVisible) {
      this.tip = undefined;
      setTimeout(() => this.customTipInput.nativeElement.focus(), 1);
    }
  }

  private validateTip(value: number): boolean {
    if (this.booking.isZeroPaymentBooking) {
      // No need to validate tip for zero payment bookings
      return true;
    }

    if (value <= this.maxTip) {
      // Tip is within the allowed range
      return true;
    }

    if (AppConfig.IS_BIG_TIP_DISABLED) {
      this.notify.error(`${this.translate.instant('TIP_NO_MORE_THAN_X')} ${this.maxTipFormatted}`);

      // Don't allow big tips
      return false;
    }

    // Allow big tip but ask for confirmation from the user
    try {
      const formattedTip = this.priceService.format(Math.round(value), this.currency);
      this.windowService.confirm(`${formattedTip} ${this.translate.instant('BIG_TIP_WARNING_MESSAGE')}`);
    } catch {
      // User cancelled
      return false;
    }

    // User confirmed
    return true;
  }

  private onCheckOut(booking: Booking) {
    switch (booking.status) {
      case 'CHECKED_OUT':
        this.actionRequest.emit(this.retryPaymentActionInfo);
        break;

      case 'PAID':
        console.log(`${this.constructor.name} -- onCheckOut: emitting showPickupConfirmationActionInfo`);
        this.actionRequest.emit(this.showPickupConfirmationActionInfo);
        break;
    }
  }

  private onRetryPayment(booking: Booking) {
    if (booking.status === 'PAID' || booking.paymentPostponedAt) {
      this.actionRequest.emit(this.showPickupConfirmationActionInfo);
    }
  }
}
