import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BookableStorageLocation, Booking, LegacyOrder, Review } from '@luggagehero/shared/interfaces';
import { SharedBookingService } from '@luggagehero/shared/services/bookings';
import { SharedShopsService } from '@luggagehero/shared/services/shops';
import { SharedStorageService } from '@luggagehero/shared/services/storage';

import { BaseModalComponent } from '../../../../../core';
import { BookingActionInfo } from './booking-action-info';

@Component({ template: '' })
export abstract class BookingActionBaseComponent extends BaseModalComponent implements OnInit {
  public currentAction: BookingActionInfo;
  public isSkipAction = false;
  public onShow: EventEmitter<void>;
  public closed: EventEmitter<void> = new EventEmitter<void>();
  public bookingChange: EventEmitter<Booking> = new EventEmitter<Booking>();
  public reviewChange: EventEmitter<Review> = new EventEmitter<Review>();
  public actionRequest: EventEmitter<BookingActionInfo> = new EventEmitter<BookingActionInfo>();
  public promptFeedback = new EventEmitter<void>();
  public promptExperiences = new EventEmitter<void>();

  private _shop: BookableStorageLocation;
  private _booking: Booking;
  private _review: Review;
  private _isLoading = false;
  private _isInitialized = false;

  constructor(
    protected bookingService: SharedBookingService,
    protected shopsService: SharedShopsService,
    protected storageService: SharedStorageService,
    protected cd: ChangeDetectorRef,
  ) {
    super();
  }

  get shop(): BookableStorageLocation {
    return this._shop;
  }
  set shop(value: BookableStorageLocation) {
    this._shop = value;
    this.cd.markForCheck();
  }

  get booking(): Booking {
    return this._booking;
  }
  @Input() set booking(value: Booking) {
    this._booking = value;
    this.cd.markForCheck();

    // Disabling this as we are emitting the event when booking is explicitly updated by the action
    // this.bookingChange.emit(value);
  }

  get review(): Review {
    return this._review;
  }
  @Input() set review(value: Review) {
    this._review = value;
    this.cd.markForCheck();

    this.reviewChange.emit(value);
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.cd.markForCheck();
  }

  get isInitialized(): boolean {
    return this._isInitialized;
  }
  set isInitialized(value: boolean) {
    this._isInitialized = value;
    this.cd.markForCheck();
  }

  get latestOrder(): LegacyOrder {
    if (!this.booking?.orderDetails) {
      return null;
    }
    return this.booking.orderDetails[this.booking.orderDetails.length - 1];
  }

  get numberOfBags(): number {
    if (!this.booking) {
      return 0;
    }
    return this.booking.luggage.normal + this.booking.luggage.hand;
  }

  get modalTitle(): string {
    if (!this.currentAction) {
      return '';
    }
    if (this.isSkipAction && this.currentAction.skipTitle) {
      return this.currentAction.skipTitle;
    }
    return this.currentAction.title;
  }

  get modalSubtitle(): string {
    if (!this.currentAction) {
      return '';
    }
    if (this.isSkipAction) {
      return this.currentAction.skipSubtitle;
    }
    return this.currentAction.subtitle;
  }

  async ngOnInit() {
    this.isLoading = true;

    // Use the cached booking from booking service or refetch the booking from API (in case the cache expired)
    this.booking =
      this.bookingService.currentBooking ||
      (await this.bookingService.getBooking(this.bookingService.currentBookingId, true));

    this.shop = await this.shopsService.getShop(this.booking.shopId);
    this.shopsService.setCurrent(this.shop);

    // Allow derived classes to perform actions base initialization
    await this.onBaseInit();

    // Set initialized to render UI
    this.isInitialized = true;

    this.isLoading = false;
  }

  onBaseInit(): Promise<void> {
    // Here to allow overriding in derived classes
    return void 0;
  }

  close() {
    this.closed.emit();
  }

  requestAction(action: BookingActionInfo) {
    this.actionRequest.emit(action);
  }

  onHidden() {
    // Allows action components to do something being closed
  }

  protected updateBooking(value: Booking) {
    this.booking = value;
    this.bookingService.currentBooking = value;

    // TODO: Remove this when all listeners are updated via booking service
    this.bookingChange.emit(value);
  }

  protected updateReview(value: Review) {
    this.review = value;
  }
}
